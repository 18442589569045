<template>
  <b-modal
    :title="
      organization ? $t('societies.edit.edit-organization') : $t('societies.import-csv.add-organizations')
    "
    :visible="visible"
    size="lg"
    footer-class="d-none"
    @show="resetForm"
    @change="$emit('change', $event)"
  >
    <div v-if="isDeleting" class="my-4 w-100 d-flex align-items-center justify-content-center flex-column">
      <h2 class="title mt-1 mb-2">
        {{ $t('unshare.simple-title') }}
        {{ profile.name }}
      </h2>
      <p>{{ $t('organizations.delete.delete-organization') }}</p>
      <div class="d-flex justify-content-center">
        <b-button variant="outline-primary" class="m-1" @click="isDeleting = false">
          {{ $t('organizations.delete.no-delete') }}
        </b-button>
        <b-button class="m-1" variant="outline-danger" @click="handleDeleteItem()">
          {{ $t('organizations.delete.sure-delete') }}
        </b-button>
      </div>
    </div>

    <!-- Body -->
    <validation-observer v-else #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
        <div v-if="organization">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            :name="$t('organizations.edit.name.name')"
            rules="max:255"
            class="mt-2"
          >
            <b-form-group :label="$t('organizations.edit.name.label')" label-for="profile-name">
              <b-form-input
                id="profile-name"
                v-model="profile.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('organizations.edit.name.placeholder')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Headline -->
          <b-form-group :label="$t('organizations.edit.headline.label')" label-for="profile-headline">
            <form-translation-table
              
              id="profile-headline"
              v-model="profile.headline"
              :name="$t('organizations.edit.headline.name')"
              :placeholder="$t('organizations.edit.headline.placeholder')"
              rules="max:255"
              :disabled="isSaving"
            />
          </b-form-group>
          <!-- Description -->
          <b-form-group :label="$t('organizations.edit.description.label')" label-for="profile-description">
            <form-translation-table
              :isDescription=true
              :isRich=true
              id="profile-description"
              v-model="profile.description"
              :name="$t('organizations.edit.description.name')"
              :placeholder="$t('organizations.edit.description.placeholder')"
              :disabled="isSaving"
            >
              <template #input="{ value, setValue, state, disabled }">
                <plain-editor
                  id="profile-description"
                  :value="value"
                  class="form-control text-editor"
                  :placeholder="$t('organizations.edit.description.placeholder')"
                  :state="state"
                  :disabled="disabled"
                  @input="setValue"
                />
              </template>
            </form-translation-table>
          </b-form-group>
          <!-- employeeNumber -->
          <validation-provider
            #default="validationContext"
            :name="$t('organizations.edit.employeeNumber.name')"
            rules="integer|min_value:0"
          >
            <b-form-group :label="$t('organizations.edit.employeeNumber.label')" label-for="profile-employeeNumber">
              <b-form-input
                id="profile-employeeNumber"
                v-model="profile.employeeNumber"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('organizations.edit.employeeNumber.placeholder')"
                :disabled="isSaving"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- FoundationDate -->
          <validation-provider #default="validationContext" :name="$t('organizations.edit.foundationDate.name')">
            <b-form-group :label="$t('organizations.edit.foundationDate.label')" label-for="profile-foundationDate">
              <flat-pickr
                id="profile-foundationDate"
                v-model="profile.dateFounded"
                class="form-control bg-white"
                :config="foundationDateConfig"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Locations array -->
          <validation-provider v-slot="validationContext" :name="$t('organizations.edit.locations.name')">
            <b-form-group :label="$t('organizations.edit.locations.label')" label-for="profile-location">
              <places-map-select
                v-model="profile.locations"
                :placeholder="$t('organizations.edit.locations.placeholder')"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <br>

          <!-- Links[] -->
          <b-form-group>
            <Links 
              :profile-links="profile.links" 
              @save-links="handleSaveLinks" 
            />
          </b-form-group>

          <!-- Emails[] -->
          <b-form-group>
            <Emails 
              :mails="profile.emails" 
              @save-emails="handleSaveEmails" 
            />
          </b-form-group>

          <!-- Phones[] -->
          <b-form-group>
            <Phones 
              :profile-phones="profile.phones" 
              @save-phones="handleSavePhones" 
            />
          </b-form-group>

          <!-- Logo -->
          <b-form-group :label="$t('organizations.edit.logo.label')" label-for="profile-logo">
            <file-preview
              v-if="profile.logoURL"
              :src="getImageResource(profile.logoURL)"
              :placeholder-image="avatarPlaceholder"
              mime-type="image"
              cancelable
              @cancel="profile.logoURL = null"
            />
            <file-upload
              v-else
              v-model="logo"
              type="image"
              class=" mw-100"
              cancelable
              :placeholder="$t('organizations.edit.logo.placeholder')"
              :drop-placeholder="$t('organizations.edit.logo.drop-placeholder')"
              @cancel="logo = null"
            />
          </b-form-group>
        </div>
        <b-tabs v-if="!organization">
          <b-tab
            active
            :title="$t('organizations.scratch')"
            content-class="mt-2"
            class="mt-2"
            title-link-class="px-0 font-weight-bolder"
          >
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              :name="$t('organizations.edit.name.name')"
              rules="max:255"
              class="mt-2"
            >
              <b-form-group :label="$t('organizations.edit.name.label')" label-for="profile-name">
                <b-form-input
                  id="profile-name"
                  v-model="profile.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="$t('organizations.edit.name.placeholder')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Headline -->
            <b-form-group :label="$t('organizations.edit.headline.label')" label-for="profile-headline">
              <form-translation-table
                id="profile-headline"
                v-model="profile.headline"
                :name="$t('organizations.edit.headline.name')"
                :placeholder="$t('organizations.edit.headline.placeholder')"
                rules="max:255"
                :disabled="isSaving"
              />
            </b-form-group>
            <!-- Description -->
            <b-form-group :label="$t('organizations.edit.description.label')" label-for="profile-description">
              <form-translation-table
                id="profile-description"
                v-model="profile.description"
                :name="$t('organizations.edit.description.name')"
                :placeholder="$t('organizations.edit.description.placeholder')"
                :disabled="isSaving"
                :isDescription=true
                :isRich=true
              >
                <template #input="{ value, setValue, state, disabled }">
                  <plain-editor
                    id="profile-description"
                    :value="value"
                    class="form-control text-editor"
                    :placeholder="$t('organizations.edit.description.placeholder')"
                    :state="state"
                    :disabled="disabled"
                    @input="setValue"
                  />
                </template>
              </form-translation-table>
            </b-form-group>
            <!-- employeeNumber -->
            <validation-provider
              #default="validationContext"
              :name="$t('organizations.edit.employeeNumber.name')"
              rules="integer|min_value:0"
            >
              <b-form-group :label="$t('organizations.edit.employeeNumber.label')" label-for="profile-employeeNumber">
                <b-form-input
                  id="profile-employeeNumber"
                  v-model="profile.employeeNumber"
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="$t('organizations.edit.employeeNumber.placeholder')"
                  :disabled="isSaving"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- FoundationDate -->
            <validation-provider #default="validationContext" :name="$t('organizations.edit.foundationDate.name')">
              <b-form-group :label="$t('organizations.edit.foundationDate.label')" label-for="profile-foundationDate">
                <flat-pickr
                  id="profile-foundationDate"
                  v-model="profile.dateFounded"
                  class="form-control bg-white"
                  :config="foundationDateConfig"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Locations array -->
            <validation-provider v-slot="validationContext" :name="$t('organizations.edit.locations.name')">
              <b-form-group :label="$t('organizations.edit.locations.label')" label-for="profile-location">
                <places-map-select
                  v-model="profile.locations"
                  :placeholder="$t('organizations.edit.locations.placeholder')"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Links[] -->
            <b-form-group>
              <Links 
                :profile-links="profile.links" 
                @save-links="handleSaveLinks" 
              />
            </b-form-group>
            
            <!-- Emails[] -->
            <b-form-group>
              <Emails 
                :profile-emails="profile.emails" 
                @save-emails="handleSaveEmails" 
              />
            </b-form-group>
            
            <!-- Phones[] -->
            <b-form-group>
              <Phones 
                :profile-phones="profile.phones" 
                @save-phones="handleSavePhones" 
              />
            </b-form-group>

            <!-- Logo -->
            <b-form-group :label="$t('organizations.edit.logo.label')" label-for="profile-logo">
              <file-preview
                v-if="profile.logoURL"
                :src="getImageResource(profile.logoURL)"
                :placeholder-image="avatarPlaceholder"
                mime-type="image"
                cancelable
                @cancel="profile.logoURL = null"
              />
              <file-upload
                v-else
                v-model="logo"
                type="image"
                class=" mw-100"
                cancelable
                :placeholder="$t('organizations.edit.logo.placeholder')"
                :drop-placeholder="$t('organizations.edit.logo.drop-placeholder')"
                @cancel="logo = null"
              />
            </b-form-group>
          </b-tab>
          <b-tab
            :title="$t('organizations.import-from')"
            content-class="mt-2"
            class="mt-2"
            title-link-class="px-0 font-weight-bolder  ml-2"
          >
            <div v-if="!organization" class="d-flex flex-wrap align-items-center justify-content-between my-1">
              <b-button
                variant="link"
                class="p-0 mt-50 pointer font-weight-bold"
                @click="uploadFileVisible = !uploadFileVisible"
              >
                {{ $t('societies.import-csv.title') }}
              </b-button>
              <b-badge
                v-if="!organization"
                variant="light-primary"
                class="ml-25 mt-50 font-weight-normal pointer"
                @click="downloadCsv()"
              >
                <feather-icon icon="DownloadIcon" class="mr-25" />{{$t('download.csv')}}
              </b-badge>
            </div>
            <div v-if="uploadFileVisible && !organization">
              <file-upload
                v-model="csvFile"
                type="csv"
                class="w-100 my-1"
                cancelable
                :placeholder="$t('media.form.file.placeholder')"
                :drop-placeholder="$t('media.form.file.drop-placeholder')"
              />
            </div>
          </b-tab>
        </b-tabs>
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="isSaving || (profile.name === '' && csvFile === null)"
          >
            <span
              v-if="isSaving"
            ><b-spinner
               label="Sending..."
               variant="white"
               class="mr-50"
               small
             />
              {{ $t('backoffice.subscriptions.form.action.submit') }}
            </span>

            <span v-else>{{ $t('form.actions.save') }}</span>
          </b-button>
          <b-button
            v-if="organization"
            class="mr-2"
            variant="outline-danger"
            @click="isDeleting = true"
          >
            {{ $t('unshare.simple-title') }}
          </b-button>
          <b-button
            type="reset"
            class="mr-2"
            variant="outline-secondary"
            :disabled="isSaving"
          >
            {{ $t('action.reset') }}
          </b-button>
          <b-button
            variant="outline-secondary"
            size="sm"
            :disabled="isSaving"
            @click="$emit('change', false)"
          >
            {{ $t('action.cancel') }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import FlatPickr from 'vue-flatpickr-component';

import PlainEditor from '@core/components/editor/PlainEditor.vue';
import PlacesMapSelect from '@core/components/places-map-input/PlacesMapSelect.vue';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';
import FormCouples from '@core/components/form-couples/FormCouples.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import Links from "@/views/apps/contacts/components/Links.vue";
import Emails from "@/views/apps/contacts/components/Emails.vue";
import Phones from "@/views/apps/contacts/components/Phones.vue";

import formValidation from '@core/comp-functions/forms/form-validation';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';
import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';
import HeaderPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import OrganizationsPlaceholder from '@/assets/images/placeholders/light/organizations.svg';
import {
  required, max, email, minValue, integer,
} from '@validations';
import { getImageResource } from '@/@core/utils/image-utils';
// import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { OrganizationListingType } from '@copernicsw/community-common';

export default {
  name: 'OrganizationEditModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    FlatPickr,
    PlainEditor,
    PlacesMapSelect,
    FormTranslationTable,
    FormCouples,
    FileUpload,
    FilePreview,
    vSelect,
    FileUpload,
    Links,
    Emails,
    Phones,
  },
  mixins: [ToastNotificationsMixin, ToastificationContentVue],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    typeKey: { type: String, default: null },
    visible: Boolean,
    organization: {
      type: Object,
      default: () => {},
    },
    classifiers: {
      type: Array,
      default: () => [],
    },
    listingType: { type: Number, default: OrganizationListingType.forCommunity },
    isMemberProfile: Boolean,
  },
  data() {
    return {
      profile: {
        name: '',
        headline: {}, // translation
        description: {}, // translation table
        employeeNumber: null,
        dateFounded: null,
        locations: [],
        links: [],
        emails: [],
        phones: [],
        logoURL: null,
        backgroundURL: null,
      },

      isDeleting: false,
      logo: null,
      background: null,
      isSaving: false,
      required,
      max,
      email,
      minValue,
      integer,
      csvFile: null,
      uploadFileVisible: false,
      csvTemplateOrganizations: [
        [
          'Name of the organization 1',
          'Headline of the organization 1',
          'Example description for the organization 1',
          'email1@example.com',
          'www.example1.org',
        ],
        [
          'Name of the organization 2',
          'Headline of the organization 2',
          'Example description for the organization 2',
          'email2@example.com',
          'www.example2.org',
        ],
      ],
    };
  },
  computed: {
    foundationDateConfig() {
      return {
        wrap: true,
        enableTime: false,
        dateFormat: 'Z',
        altInput: true,
        altFormat: 'Y-m-d',
        disableMobile: 'true',
      };
    },
    // linkLabelsOptions() {
    //   return ActiveLinksLabels.map((label) => ({
    //     text: this.$t(`links.label.${label}`),
    //     value: label,
    //   }));
    // },
    canAddClassifiers() {
      return this.$store.getters.currentCollective.isStaff;
    },
    communityClassifiersData() {
      return this.$store.getters.classifiers.community.unpaginated;
    },
    communityClassifiers() {
      return Object.values(this.communityClassifiersData);
    },
    isClassifiersSectionVisible() {
      return this.canAddClassifiers && this.communityClassifiers?.length > 0;
    },
    filterOptions() {
      return Object.keys(this.communityClassifiers);
    },
    avatarPlaceholder() {
      return OrganizationsPlaceholder;
    },
    backgroundPlaceholder() {
      return HeaderPlaceholder;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
  },
  watch: {
    organization() {
      this.resetForm();
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});

    return {
      getValidationState,
    };
  },
  async created() {
    await this.resetForm();
    if (this.organization) {
      this.profile = { ...this.organization };
    }
    console.log('this.profile', this.profile);
  },
  methods: {
    handleSaveLinks(links) {
      this.profile.links = links;
    },
    handleSaveEmails(emails) {
      this.profile.emails = emails;
    },
    handleSavePhones(phones) {
      // console.log('phones', phones);
      this.profile.phones = phones;
    },
    getImageResource,
    resetForm() {
      this.profile = {
        name: '',
        employeeNumber: null,
        dateFounded: null,
        logoURL: null,
        backgroundURL: null,
        ...this.organization,
        links: (this.organization?.links || []).map(({ key, url, label, name }) => ({ key, url, label, name })),
        emails: JSON.parse(JSON.stringify(this.organization?.emails || [])),
        phones: JSON.parse(JSON.stringify(this.organization?.phones || [])),
        locations: JSON.parse(JSON.stringify(this.organization?.locations || [])),
        headline: JSON.parse(JSON.stringify(this.organization?.headline || {})),
        description: JSON.parse(JSON.stringify(this.organization?.description || {})),
      };
      if (!this.organization) {
        this.logo = null;
        this.background = null;
      }
      this.csvFile = null;

      if (this.$refs.refFormObserver) {
        this.$refs.refFormObserver.reset();
      }
    },
    downloadCsv() {
      let csv = 'name;headline;description;email;website\r\n';
      this.csvTemplateOrganizations.forEach((row) => {
        csv += row.join(';');
        csv += '\r\n';
      });
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv.replace(/,/g, ';'))}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'CSV_template_societies.csv';
      hiddenElement.click();
    },
    async onSubmit() {
      this.isSaving = true;
      try {
        if (this.organization || this.csvFile !== null) {
          if (this.csvFile !== null) {
            await this.$store.dispatch('importFiles', {
              importType: 'backoffice/organizations/import',
              file: this.csvFile,
              forceUpdate: true,
              customName: 'organizations',
              storedKey: this.typeKey !== null ? this.typeKey : this.communitySlug,
              listingType: OrganizationListingType.forCommunity,
            });
            this.uploadFileVisible = false;
            this.notifySuccess(this.$t('organizations.import-csv.create-success'));
          }
          if (this.organization) {
            await this.updateProfile();
          }
        }
        if (this.profile.name !== '' && !this.organization) {
          await this.createOrganization();
        }
        this.$emit('profile-updated');
        this.$emit('change', false);
      } catch (error) {
        this.notifyError(this.$t('error-message.general-error'));
      }

      this.isSaving = false;
    },
    async updateProfile() {
      try {
        const file = {};

        if (this.logo != null) {
          file.logoURL = this.logo;
        }

        if (this.background != null) {
          file.backgroundURL = this.background;
        }

        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'organizations',
            storedKey: this.typeKey !== null ? this.typeKey : this.communitySlug,
            requestConfig: {
              name: this.profile.name,
              organizationKey: this.organization.key,
              headline: this.profile.headline,
              description: this.profile.description,
              dateFounded: this.profile.dateFounded,
              employeeNumber: this.profile.employeeNumber,
              links: this.profile.links,
              emails: this.profile.emails,
              phones: this.profile.phones,
              location: this.profile.locations,
              isLogo: this.profile.logoURL != this.organization.logoURL,
              isBackground: this.profile.backgroundURL != this.organization.backgroundURL,
            },
          },
          file,
        });
        this.notifySuccess(this.$t('organizations.edit.success-message'));
      } catch (e) {
        console.log(e);
        this.$toast({
          component: ToastificationContentVue,
          props: { title: this.$t('error-message.general-error'), icon: 'AlertTriangleIcon', variant: 'danger' },
        });
      }
    },
    async createOrganization() {
      try {
        await this.$store.dispatch('createItem', {
          noSet: true,
          ...((this.$store.getters.mainCollective?.key && this.isMemberProfile) ? { inMain: true } : ''),
          item: {
            storedKey: this.typeKey !== null ? this.typeKey : this.communitySlug,
            itemType: 'organizations',
            requestConfig: {
              name: this.profile.name,
              headline: this.profile.headline,
              description: this.profile.description,
              dateFounded: this.profile.dateFounded,
              employeeNumber: this.profile.employeeNumber,
              links: this.profile.links,
              emails: this.profile.emails,
              phones: this.profile.phones,
              location: this.profile.locations,
              typeKey: this.typeKey !== this.communitySlug ? this.typeKey : undefined,
            },
          },
          file: { logoURL: this.logo, backgroundURL: this.background },
        });
        this.notifySuccess(this.$t('organizations.create.success-message'));
        this.$emit('created', true);
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: { title: this.$t('error-message.general-error'), icon: 'AlertTriangleIcon', variant: 'danger' },
        });
      }
    },
    async handleDeleteItem() {
      try {
        const response = await this.$store.dispatch('deleteItem', {
          item: {
            storedKey: this.typeKey !== null ? this.typeKey : this.communitySlug,
            itemType: 'organizations',
            requestConfig: {
              organizationKey: this.organization.key,
              memberKeys: [0, this.organization.key],
            },
          },
        });
        this.$router.push({ name: 'organizations' });
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: { title: this.$t('error-message.general-error'), icon: 'AlertTriangleIcon', variant: 'danger' },
        });

        this.isDeleting = false;
      }
    },
  },
  translateTranslationTable,
  classifierStyle(classifier) {
    return {
      'background-color': classifier.backgroundColor,
      // eslint-disable-next-line quote-props
      color: classifier.fontColor,
    };
  },
  iconClassifierStyle(classifier) {
    return {
      // eslint-disable-next-line quote-props
      color: classifier.iconColor,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.text-editor::v-deep {
  height: auto;
  .ql-editor {
    min-height: 96px;
  }
}
</style>
